<template>
  <div v-show="hooks.length > 0">
    <component
      :is="hook.component"
      v-for="(hook,index) in hooks"
      :key="index"
      v-bind="$attrs"
      :hook-data="Array.isArray(hook.data)?{}:hook.data"
      v-on="$listeners"
    />
  </div>
</template>

<script>
/*global _*/
export default {
    props:{
      name:{
        type:String,
        required:true
      }
    },

    computed:{
      hooks(){
        return this.$root.$options.$hooks ? _.get(this.$root.$options.$hooks,this.name,[]) : []
      },
    }
}
</script>
